<template>
  <div v-if="avvioRicerca">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-6 text-start" v-if="!readOnly">
        <button
          type="button"
          class="badge bg-secondary rounded blueFit fs-5"
          @click="richiediTrasferimento()"
        >
          Richiedi Trasferimento
        </button>
      </div>
      <div class="col-12">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          tesserati di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        trasferimenti_richieste_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="trasferimenti_richieste_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        :checkedAll="checkAll"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsTrasfRichieste"
        @current-change="setCurrentPageTrasfRichieste"
        @checkboxClicked="
          checkAll = !checkAll;
          trasferimenti_richieste_list.forEach((element) => {
            element.selected = checkAll;
            if (!checkAll) element.id_articolo_svincolo = null;
          });
        "
      >
        <template v-slot:cell-checkbox="{ row: data }">
          <TextInput
            name="privacy_1"
            type="checkbox"
            class="mt-3 p-0"
            :checked="data.selected"
            :value="data.selected ? (privacy_1 = true) : (privacy_1 = false)"
            @change="
              checkAll = false;
              data.selected = !data.selected;
              data.id_articolo_svincolo = null;
            "
        /></template>
        <template v-slot:cell-avvisoUnder="{ row: data }">
          <Popper
            arrow
            content="Attenzione: verificare l'eventuale pagamento di indennità"
            v-if="data.vivaio"
          >
            <button><i class="bi bi-info-circle-fill"></i></button>
          </Popper>
        </template>
        <template v-slot:cell-tipo_svincolo="{ row: data }">
          <SelectInput
            :options="trasferimenti_svincolo"
            name="svincolo"
            id="svincolo"
            :value="data.id_articolo_svincolo"
            @changeSelect="data.id_articolo_svincolo = parseInt($event)"
            :disabled="!data.selected"
        /></template>
        <template v-slot:cell-tesserato="{ row: data }"
          ><div class="tab-long">
            {{ data.nominativo_completo }}
          </div></template
        >
        <template v-slot:cell-tessera="{ row: data }">{{
          data.codice_tessera
        }}</template>
        <template v-slot:cell-categoria="{ row: data }"
          ><div class="">{{ data.tipologia }}</div></template
        >
        <template v-slot:cell-categoria2="{ row: data }"
          ><div class="tab-long">
            {{ data.disciplina }} / {{ data.cat_eta }} / {{ data.rank }}
          </div></template
        >
        <!-- <template v-slot:cell-categoria3="{ row: data }"
          ><div class="">{{ data.cat_eta }}</div></template
        >
        <template v-slot:cell-categoria4="{ row: data }"
          ><div class="">{{ data.rank }}</div></template
        > -->
        <template v-slot:cell-avvisoRichiesta="{ row: data }">
          <span
            class="text-success fw-bold text-wrap"
            v-if="data.richiestaEsito == 1"
          >
            Richiesta effettuata
          </span>
          <span
            class="text-danger fw-bold text-wrap"
            v-if="data.richiestaEsito == 0"
          >
            Errore
            <Popper>
              <button>
                <i class="bi bi-info-circle-fill text-danger fw-bold"></i>
              </button>
              <template #content>
                <div v-html="data.messaggioErrore"></div>
              </template>
            </Popper>
          </span>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, getCurrentInstance } from "vue";
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import Popper from "vue3-popper";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import { addTrasferimentoRichiesta } from "@/requests/tesseratiTrasferimentiRichieste";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import isEnabled from "@/composables/isEnabled.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertSuccess, alertFailed } from "@/composables/swAlert";

export default {
  name: "TableRichiesteTrasferimenti",
  components: { Datatable, Popper, SelectInput, TextInput, Loading },
  emits: [
    "getRichiesteTrasferimentiList",
    "resetFilters",
    "setAvvisoObbRichiesta",
  ],
  props: {
    avvioRicerca: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const trasferimenti_svincolo = computed(() =>
      store.getters.getStateFromName("trasferimenti_svincolo")
    );

    const keys = ref("ttsv");
    if (!trasferimenti_svincolo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setFetchRowsTrasfRichieste = (e) => {
      store.commit("setFetchRowsTrasfRichieste", e);
      emit("getRichiesteTrasferimentiList");
    };
    const setCurrentPageTrasfRichieste = (page) => {
      store.commit("setCurrentPageTrasfRichieste", page);
      emit("getRichiesteTrasferimentiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTrasfRichieste", columnName);
      store.commit("setSortOrderTrasfRichieste", order);
      emit("getRichiesteTrasferimentiList");
    };

    const idSocietaProvTrasfRichieste = computed(
      () => store.getters.idSocietaProvTrasfRichieste
    );
    const idSocietaDestTrasfRichieste = computed(
      () => store.getters.idSocietaDestTrasfRichieste
    );
    const annoProvTrasfRichieste = computed(
      () => store.getters.annoProvTrasfRichieste
    );
    const annoDestTrasfRichieste = computed(
      () => store.getters.annoDestTrasfRichieste
    );

    //const trasferimenti_richieste_list = ref([]);
    const trasferimenti_richieste_list = computed(() =>
      store.getters.getStateFromName("resultstrasferimenti_richieste_list")
    );
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtrasferimenti_richieste_list")
    );

    store.commit("assignStateValue", {
      value: true,
      stateName: "loadedtrasferimenti_richieste_list",
    });

    const isLoading = ref(false);
    const fullPage = ref(true);

    const richiediTrasferimento = () => {
      let richiesta = [];
      let contatore = 0;
      trasferimenti_richieste_list.value.forEach((element) => {
        if (element.selected) {
          let richiestaElement = {};
          richiestaElement.contatore = contatore;
          richiestaElement.anno = annoDestTrasfRichieste.value;
          richiestaElement.anno_prov = annoProvTrasfRichieste.value;
          richiestaElement.id_societa_prov = idSocietaProvTrasfRichieste.value;
          richiestaElement.id_societa_dest = idSocietaDestTrasfRichieste.value;
          richiestaElement.id_persona = element.id_persona;
          richiestaElement.id_tesseramento = element.id_tessera;
          richiestaElement.id_articolo_svincolo = element.id_articolo_svincolo;
          richiesta.push(richiestaElement);
          //element.richiestaEsito = 1;
        }
        contatore++;
      });
      if (
        richiesta.length == 0 ||
        annoDestTrasfRichieste.value == null ||
        annoProvTrasfRichieste.value == null ||
        idSocietaProvTrasfRichieste.value == null ||
        idSocietaDestTrasfRichieste.value == null ||
        idSocietaProvTrasfRichieste.value == idSocietaDestTrasfRichieste.value
      ) {
        emit("setAvvisoObbRichiesta");
      } else {
        Swal.fire({
          html: "Sei proprio sicuro di voler richiedere i trasferimenti dei tesseramenti selezionati?",
          showCancelButton: true,
          confirmButtonText: "Conferma",
          cancelButtonText: "Annulla",
        }).then((result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            const numeroRichieste = richiesta.length;
            const numeroRichiesetCompletate = ref(0);
            richiesta.forEach((element) => {
              let responseAdd = addTrasferimentoRichiesta(element);
              responseAdd.then((value) => {
                if (value.data.statusCode == 200) {
                  trasferimenti_richieste_list.value[
                    element.contatore
                  ].richiestaEsito = 1;
                  trasferimenti_richieste_list.value[
                    element.contatore
                  ].selected = false;
                  trasferimenti_richieste_list.value[
                    element.contatore
                  ].id_articolo_svincolo = null;
                } else {
                  trasferimenti_richieste_list.value[
                    element.contatore
                  ].richiestaEsito = 0;
                  trasferimenti_richieste_list.value[
                    element.contatore
                  ].messaggioErrore = value.data.message;
                }

                numeroRichiesetCompletate.value++;
                if (numeroRichiesetCompletate.value === numeroRichieste) {
                  isLoading.value = false;
                  alertSuccess(
                    "Richieste di trasferimento completate. Verificare il loro stato nella tabella "
                  );
                }
              });
            });
          }
        });
      }
    };

    const data = [
      {
        tipo_svincolo: "",
        nome: "mario",
        cognome: "rossi",
        data_nascita: "01/01/01",
        tessera: "154281259",
        tipo_tessera: "Atleta",
        disciplina: "Tennis",
        categoria: "Non Agonista / Tennis / Over 60",
        selected_member: false,
      },
    ];
    const tableHeader = ref([
      {
        key: "checkbox",
        sortable: false,
      },
      {
        key: "avvisoUnder",
        sortable: false,
      },
      {
        name: "Tipo Svincolo",
        key: "tipo_svincolo",
      },
      {
        name: "Tesserato",
        key: "tesserato",
      },
      {
        name: "Numero Tessera",
        key: "tessera",
      },
      {
        name: "Categoria",
        key: "categoria",
      },

      {
        name: "Tipo tessera",
        key: "categoria2",
        sortable: false,
      },
      /* {
        name: "",
        key: "categoria2",
        sortable: false,
      },
      {
        name: "",
        key: "categoria3",
        sortable: false,
      },
      {
        name: "",
        key: "categoria4",
        sortable: false,
      }, */
      {
        key: "avvisoRichiesta",
        sortable: false,
      },
    ]);

    const checkAll = ref(false);

    return {
      trasferimenti_richieste_list,
      loaded,
      record: computed(() =>
        store.getters.getStateFromName("recordtrasferimenti_richieste_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statustrasferimenti_richieste_list")
      ),

      currentPage: computed(() => store.getters.currentPageTrasfRichieste),
      rowsToSkip: computed(() => store.getters.rowsToSkipTrasfRichieste),
      fetchRows: computed(() => store.getters.fetchRowsTrasfRichieste),
      sortColumn: computed(() => store.getters.sortColumnTrasfRichieste),
      sortOrder: computed(() => store.getters.sortOrderTrasfRichieste),
      setFetchRowsTrasfRichieste,
      setCurrentPageTrasfRichieste,
      setSortOrderColumn,
      tableHeader,
      data,
      trasferimenti_svincolo,
      richiediTrasferimento,
      isLoading,
      fullPage,
      checkAll,
      isEnabled,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
};
</script>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
